import React, { Component } from "react";
import { PageHeader, SearchForm, Section } from "../../components";
import "./Home.css"

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeForm: props.match.params.doc_group || 'trees'
    };
  }


  // Change search form type
  changeFormType(formType) {
    this.setState({
      activeForm: formType
    });
  }

  render() {
    const { activeForm } = this.state;
    return (
      <>
        <Section className="section--header">
          <PageHeader history={this.props.history} />
        </Section>

        {/*
        <Section className="section--select-area">
          {Object.keys(config.docGroups).map(item =>
            <button
              className={(item === activeForm)
                ? "document-type-select document-type-select--active"
                : "document-type-select"
              }
              key={item}
              onClick={() => this.changeFormType(item)}>
              {config.docGroups[item].name}
            </button>
          )}
        </Section>
        */}

        <Section className="section--content">
          <SearchForm
            docGroup={activeForm}
          />
        </Section>
      </>
    );
  }
}

export default Home;
