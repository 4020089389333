import React, { useContext, useEffect } from 'react';
import {
  Route,
  Switch,
  BrowserRouter
} from "react-router-dom";

import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

/* Import routes */
import Home from "routes/Home/Home"
import Garden from "routes/Garden/Garden"
import Tree from "routes/Tree/Tree"
import Origin from "routes/Origin/Origin"
import config from 'config';

import { store } from 'store';
const { apiUrl, baseUrl } = config;



const FormDialog = () => {
  const [open, setOpen] = React.useState(true);
  const [password, setPassword] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleLogIn = () => {
    setOpen(true);

    // Example POST method implementation:
    async function postData(url = '', data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
        //mode: 'cors', // no-cors, *cors, same-origin
        //redirect: 'follow', // manual, *follow, error
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        //credentials: 'same-origin', // include, *same-origin, omit
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }

    postData(baseUrl + "login", { username: username, password: password })
      .then(data => {
        if (data.loggedIn) {
          console.log(config)
          window.location.href = config.adminUrl;
        } else {
          setErrorMessage(data.message)
        }
      });

  };

  const handleClose = () => {
    setOpen(false);
    window.location.href = "/";
  };

  const handleUserNameChange = (event) => {
    setUsername(event.target.value)
  }

  const handlePassWordChange = (event) => {
    setPassword(event.target.value)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText>
          <TextField
            value={username}
            onChange={handleUserNameChange}
            autoFocus
            margin="dense"
            id="username"
            label="User name"
            fullWidth
          />
          <TextField
            value={password}
            onChange={handlePassWordChange}
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            helperText={errorMessage}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogIn} color="primary">
            Log in
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const Login = () => {
  const { state } = useContext(store);

  if (Object.keys(state).length !== 0 && state.constructor === Object) {
    window.location.href = config.adminUrl;
    return null;
  } else {
    return (<FormDialog></FormDialog>)
  }
}


function App() {

  const { dispatch } = useContext(store);

  async function verifyLoginToken() {
    const response = await fetch(apiUrl + 'logged', {
      method: 'GET',
      credentials: 'include', // include, *same-origin, omit
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  useEffect(() => {
    verifyLoginToken().then(data => {
      if (data.loggedIn) {
        dispatch({ type: 'login', user: 'Paavo Kallio' })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Home} />

          <Route path='/search/:doc_group' component={Home} />

          <Route
            path='/garden/:id'
            render={({ match, history }) => (
              <Garden
                history={history}
                gardenId={match.params.id}
              />
            )}
          />

          <Route exact path='/gardens' component={Garden} />

          <Route
            path='/tree/:id'
            render={({ match, history }) => (
              <Tree
                history={history}
                treeId={match.params.id}
              />
            )}
          />

          <Route exact path='/origins' component={Origin} />

          <Route exact path='/auth' component={Login} />

          {/* remove if not wanted */}
          <Route
            path='/about'
            render={({ history }) => (
              <div history={history}> about us page</div>
            )}
          />

        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
