import React, { useEffect, useState, useRef } from 'react';
import { PageHeader, Section } from "../../components";
import {
    Grid,
    Paper,
    List, ListItem, ListItemText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './Garden.css';
import config from '../../config';
import { setQueryStringWithoutPageReload, getQueryStringValue } from '../../utils/queryString'
const { gardenCoordinates } = config

require("proj4leaflet");
var L = require("leaflet-mml-layers");


const Garden = () => {

    // map needs to be set up after the DOM has been rendered
    const [map, setMap] = useState(null)
    const [renderer] = useState(L.canvas({ padding: 0.5 }))
    const [markerGroup] = useState(L.layerGroup())
    const [selectedGarden, setSelectedGarden] = useState()

    // set markers when coordinates change
    const mounted = useRef();

    const useStyles = makeStyles((theme) => ({
        gardenList: {
            maxWidth: 200,
            margin: 20,
            marginTop: 0,
            backgroundColor: theme.palette.background.paper,
        },
        selected: {
            backgroundColor: '#5f9ea0',
        },
        listHeading: {
            margin: 0,
            padding: 20,
        },
        gardenInfoSection: {
            padding: 20,
        },
        gardenData: {
            height: 750,
        },
        header: {
            margin: '1rem 5rem',
        }
    }));

    const classes = useStyles();


    function markerOnClick(e) {
        let gardenId = e.sourceTarget.options.garden
        handleToggle(gardenId)
    }


    useEffect(() => {
        mounted.current = true

        let preSelectedGardenCode = getQueryStringValue('garden')

        if (preSelectedGardenCode) {
            handleToggle(preSelectedGardenCode)
        }

        // Define coordinate systems (TODO: 3067Proj don't work with overlaymap!!!)
        // var mml_crs    = L.TileLayer.MML.get3067Proj();     // MML Finnish maps
        var normal_crs = L.CRS.EPSG3857;                    // OSM, Google and other world wide maps

        // Define maps
        var baseMaps = {
            "Taustakartta": L.tileLayer.mml('Taustakartta'),
            "Selkokartta": L.tileLayer.mml('Peruskartta'),
            "Ilmakuva": L.tileLayer.mml('Ortokuva'),
        };

        // set custom image layers (orthomosaic, dtm, dsm)
        let overLays = {}

        // Start with MML map
        let initMap = L.map('gardensMap', {
            crs: normal_crs,
            //crs: mml_crs,
            preferCanvas: true
        }).setView([65.9600, 25.5900], 5)

        // Default to maastokartta
        initMap.addLayer(baseMaps["Taustakartta"]);
        //L.control.layers(baseMaps).addTo(initMap);
        L.control.layers(baseMaps, overLays, { collapsed: false }).addTo(initMap);

        //initMap.fitBounds([[69.7701277329, 27.0153603323], [69.7745129055, 27.0105164285]]);

        initMap.on('click', function (e) {
            var coord = e.latlng;
            var lat = coord.lat;
            var lng = coord.lng;
            console.log("You clicked the map at latitude: " + lat + " and longitude: " + lng);
        });

        // Set initialized map to state
        setMap(initMap)

    }, [])

    const updateMarkerGroups = () => {
        markerGroup.clearLayers();

        for (const [key, value] of selectedGarden ? Object.entries(selectedGarden) : Object.entries(gardenCoordinates)) {
            L.circleMarker(value.center, {
                renderer: renderer,
                color: '#ff0000',
                garden: key
            })
                .on('click', markerOnClick)
                .addTo(markerGroup)
        }

        map.addLayer(markerGroup);
    }

    // trigger only on first render
    if (mounted.current) {
        updateMarkerGroups()
    }


    const handleToggle = (value) => {
        let setValue;
        if (value === 'all') {
            setValue = null
        } else {
            setValue = Object.keys(gardenCoordinates)
                .filter(key => key.startsWith(value))
                .reduce((obj, key) => {
                    obj[key] = gardenCoordinates[key]
                    return obj
                }, {})
        }

        setSelectedGarden(setValue)
    }

    return (
        <>
            <Section className="section--header">
                <PageHeader />
            </Section>

            <h1 className={classes.header}>
                Gardens
            </h1>


            <Section className="section--content">
                <Grid
                    container
                    justify="center"
                    spacing={2}
                >

                    <Grid item sm={12} md={6} >
                        <Paper elevation={2}>
                            <div id="gardensMap"></div>
                        </Paper>
                    </Grid>

                    <Grid item sm={12} md={6} >
                        <Paper elevation={2} >
                            <div className={classes.gardenData}>

                                <h3 className={classes.listHeading}>Select garden:</h3>

                                <List className={classes.gardenList}>
                                    {Object.keys(config.gardenCoordinates).map((value) => {
                                        const labelId = `checkbox-list-label-${value}`;
                                        return (
                                            <ListItem
                                                key={value}
                                                role={undefined}
                                                dense button
                                                onClick={() => handleToggle(value)}
                                                className={selectedGarden && Object.keys(selectedGarden)[0] === value ? classes.selected : null}
                                            >
                                                <ListItemText id={labelId} primary={value} />
                                            </ListItem>
                                        );
                                    })}
                                    <ListItem
                                        key='allGardens'
                                        role={undefined}
                                        dense button
                                        onClick={() => handleToggle('all')}
                                        className={selectedGarden ? null : classes.selected}
                                    >
                                        <ListItemText id='checkbox-list-label-all' primary='All' />
                                    </ListItem>
                                </List>

                                <div className={classes.gardenInfoSection}>
                                    <h4>Tähän tietoa puutarhasta, mm.</h4>
                                    <ul>
                                        {selectedGarden && <li> {Object.keys(selectedGarden)[0]}</li>}
                                        <li> lämpötilakertymät </li>
                                        <li> valokuvia </li>
                                    </ul>
                                </div>

                            </div>

                        </Paper>
                    </Grid>

                </Grid>
            </Section>
        </>
    )
}

export default Garden;