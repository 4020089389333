export const config = {
    /* 
     * API url environmental variable is stored in .env file.
     * Override it by creating .env.local file.
     */
    apiUrl: process.env.REACT_APP_API_URL,
    adminUrl: process.env.REACT_APP_ADMIN_URL,
    baseUrl: process.env.REACT_APP_SERVER_URL,

    /* Site settings */
    site: {
        name: 'Metsärajapuutarha'
    },

    /* Readable names for field labels etc. */
    labelMap: {
        garden: 'Garden',
        origin: 'Origin',
        year: 'Planting year'
    },

    /* Map garden name to its location */
    gardenCoordinates: {
        '1-Rassiniva': {
            //'center': [69.772530, 27.014129],
            //'bounds': [[69.7701277329, 27.0153603323], [69.7745129055, 27.0105164285]]
            'center': [69.77250, 27.01410],
            'bounds': [[69.77014, 27.01536], [69.77451, 27.01052]]
        },
        '2-Skallovaara': {
            'center': [69.81000, 27.12000],
            'bounds': [[69.81000, 27.11795], [69.81400, 27.12720]]
        },
        '3-Syysjoki': {
            'center': [69.24750, 27.23250],
            'bounds': [[69.24612, 27.22900], [69.24907, 27.23688]]
        },
        '4-1-Pakatti': {
            'center': [67.40086, 24.56002],
            'bounds': [[], []]
        },
        '4-1-Pakatti': {
            'center': [67.40086, 24.56002
            ],
            'bounds': [[], []]
        },
        '4-10A-Pakatti': {
            'center': [67.40086, 24.56002
            ],
            'bounds': [[], []]
        },
        '4-10B-Pakatti': {
            'center': [67.40086, 24.56002
            ],
            'bounds': [[], []]
        },
        '4-11A-Pakatti': {
            'center': [67.40086, 24.56002
            ],
            'bounds': [[], []]
        },
        '4-11B-Pakatti': {
            'center': [67.40086, 24.56002
            ],
            'bounds': [[], []]
        },
        '4-12-Pakatti': {
            'center': [67.40086, 24.56002
            ],
            'bounds': [[], []]
        },
        '5-Ruissalo': {
            'center': [60.4518, 22.2666],
            'bounds': [],
        }
    },

    /* 
    * DOCUMENT GROUP SETTINGS
    * ! group's main key is used in API-call urls
    * name: Readable name of the document group
    * searchPrompt: placeholder for the text field in search form
    * facetFields: fields to use in aggregating and filtering search results (same as in backend)
    * infoFields: fields to display in info-area in search results and document page (1-2)
    * tagFields: for tag-type list-fields, like 'asiasanat' (if any)
    * multiSelectFields: fields to include in preFilters (add autosuggestions also!)
    */
    docGroups: {
        trees: {
            name: 'Trees',
            searchPrompt: 'filter trees',
            facetFields: ['garden', 'genus', 'species', 'origin', 'year', 'alive', 'measurements'],
            infoFields: ['tree_no', 'garden_id', 'alive'],
            tagFields: ['asiasanat'],
            multiSelectFields: ['ministerio', 'hallitus_hk'],
        },
        /*
        origins: {
            name: 'Origins',
            searchPrompt: 'filter origins',
            facetFields: ['genus'],
            infoFields: [],
            tagFields: [],
            multiSelectFields: [],
        }
        */
    }
}

export default config;
