import React, { useEffect, useState } from 'react';
import './Tree.css';
import config from '../../config';
import { PageHeader, Section } from "../../components";
import { Paper, Grid, Link } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from "react-router-dom";

import Plot from 'react-plotly.js';

const { apiUrl } = config

const useStyles = makeStyles((theme) => ({
  SectionTitle: {
    paddingLeft: 16,
    margin: 0,
  },
  table: {
    minWidth: 650,
  },

  header: {
    margin: '1rem 5rem',
  },
  treeDataTable: {
    margin: '1rem'
  },
  forceMarginTop: {
    marginTop: '1rem'
  }
}));


const TreeMeasurements = ({
  measurements
}) => {

  const classes = useStyles()

  let plots = [];

  Object.entries(measurements).forEach(entry => {
    let key = entry[0]
    let measurements_of_type = entry[1]

    if (measurements_of_type.length === 0) {
      return
    }

    let objs = measurements_of_type.map(obj => {
      if (obj.measuring_date_accuracy === 3) {
        obj.measuring_date = obj.measuring_date.split('-')[0]
      }
      return [obj.measuring_date, obj.value || obj.male_catkin_no || obj.female_catkin_no]
    }).sort((a, b) => a[0] > b[0])

    let x = objs.map(function (value, index) { return value[0] });
    let y = objs.map(function (value, index) { return value[1] });

    plots.push(
      <Plot
        key={key}
        data={[
          {
            x: x,
            y: y,
            type: 'scatter',
            mode: key === 'height' ? 'lines+markers' : 'markers',
            marker: { color: 'red' },
          }
        ]}
        layout={{ width: 640, height: 340, title: key }}
      />
    )

  })

  return (
    <Paper elevation={2}>
      <h2 className={classes.SectionTitle}>Measurements:</h2>
      {plots}
    </Paper>
  );

}

const Comments = ({
  comments
}) => {

  const classes = useStyles()

  return (
    <Paper elevation={2}>
      <h2 className={classes.SectionTitle}>Comments:</h2>
      {comments.map(comment => (
        <List >
          <ListItem>
            <ListItemText primary={comment.comment} secondary={comment.added} />
          </ListItem>
        </List>
      ))}
    </Paper>
  )
}

const TreeData = ({
  tree,
  replica
}) => {

  // delete unnecessary keys
  delete tree['replica_id'];

  //console.log('TREE:', tree)
  //console.log('REPLICA:', replica)

  const history = useHistory();

  const classes = useStyles()

  const handleTableRowClick = (key, value) => {
    if (key === 'origin_code') {
      history.push("/origins?code=" + value);
    } else if (key === 'garden_id') {
      history.push("/gardens?garden=" + value);
    }
  }


  const formatKey = (key) => {
    key = key.replaceAll('_', ' ')
    return key.charAt(0).toUpperCase() + key.slice(1)
  }

  return (
    <Paper elevation={2} key={tree}>
      <h2 className={classes.SectionTitle}>Data:</h2>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {Object.entries({ ...tree, ...replica }).map(attr => (

              <TableRow
                key={attr[0]}
                hover={attr[0] === 'origin_code' || attr[0] === 'garden_id' ? true : false}
                onClick={event => handleTableRowClick(attr[0], attr[1])}
              >
                <TableCell >{formatKey(attr[0])}</TableCell>
                <TableCell align="right">{attr[1]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

const Tree = ({
  treeId
}) => {

  const [treeData, setTreeData] = useState(null)

  async function fetchTree(treeId) {
    try {
      const response = await fetch(apiUrl + "tree/" + treeId);
      if (!response.ok) {
        throw Error(response.statusText);
      }

      const result = await response.json();

      console.log(result)

      setTreeData(result)

    } catch (error) {
      console.log(error)
      return null
    }
  }

  useEffect(() => {
    fetchTree(treeId)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles()

  return (
    <>
      <Section className="section--header">
        <PageHeader />
      </Section>

      <h1 className={classes.header}>
        Tree
      </h1>

      <Section className="section--content">

        {treeData && (
          <Grid
            container
            justify="center"
            spacing={2}
          >
            <Grid item xs={12} md={12} md={7} spacing={2}>

              <Grid item xs={12}>
                <Paper elevation={2}>
                  <TreeData
                    tree={treeData.tree}
                    replica={treeData.replica}
                    className={classes.treeDataTable}
                  />
                </Paper>
              </Grid>

              {treeData.comments && (
                <Grid item xs={12} className={classes.forceMarginTop} >
                  <Paper elevation={2}>
                    <Comments
                      comments={treeData.comments}
                    />
                  </Paper>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={5} >
              <Paper elevation={2} >
                <TreeMeasurements
                  measurements={treeData.measurements}
                />
              </Paper>
            </Grid>
          </Grid>

        )}

      </Section>
    </>
  )
}

export default Tree;