import React from 'react';
import './Section.css'

/* SECTION COMPONENT */

const Section = ({ className, children }) => (
  <div className={className
    ? "section " + className
    : "section"
  }>
    <div className={className
      ? "section__inner " + className.split(' ').map(c => ' ' + c + '__inner')
      : "section__inner"
    }>
      {children}
    </div>
  </div>
);

export default Section;