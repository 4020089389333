// sort array alphabetically by key value
export const sortByKey = (array, key, direction) => {
    const dir = (direction === 'desc') ? -1 : 1;
    return array.sort((a, b) => {
        const x = isNaN(a[key])
            ? ('' + a[key]).toLowerCase()
            : a[key]
        const y = isNaN(b[key])
            ? ('' + b[key]).toLowerCase()
            : b[key]
        return ((x > y) ? dir : ((x < y) ? -1 * dir : 0));
    });
}
