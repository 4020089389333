import React from 'react';
import {
    Paper,
    Card,
    CardContent,
    CardActions,
    Button,
    Typography,
    Divider,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const OriginInfo = ({
    origin,
}) => {
    const useStyles = makeStyles((theme) => ({

        root: {
            minWidth: 650,
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            margin: 12,
        },
        table: {
            minWidth: 420,
        },
    }))

    const classes = useStyles()

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Origin: {origin.code}
                </Typography>
                <Typography variant="h5" component="h2">
                    {origin.genus} {origin.species}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    {origin.planting_year && 'Planted: 19' + origin.planting_year}
                </Typography>
                <Typography variant="body2" component="p">
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Site country</TableCell>
                                    <TableCell align="right">Site adrees</TableCell>
                                    <TableCell align="right">Site municipality</TableCell>
                                    <TableCell align="right">Site transfer</TableCell>
                                    <TableCell align="right">Site explanation</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row"> {origin.site_country} </TableCell>
                                    <TableCell align="right">{origin.site_address}</TableCell>
                                    <TableCell align="right">{origin.site_municipality}</TableCell>
                                    <TableCell align="right">{origin.site_transfer}</TableCell>
                                    <TableCell align="right">{origin.site_explanation}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <TableContainer component={Paper} className={classes.table}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Growth type</TableCell>
                                    <TableCell align="right">Height</TableCell>
                                    <TableCell align="right">Diameter</TableCell>
                                    <TableCell align="right">Seed collected</TableCell>
                                    <TableCell align="right">Seed collector</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row"> {origin.growth_type} </TableCell>
                                    <TableCell align="right">{origin.height}</TableCell>
                                    <TableCell align="right">{origin.diameter}</TableCell>
                                    <TableCell align="right">{origin.seed_collecting_date}</TableCell>
                                    <TableCell align="right">{origin.seed_collector}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Divider orientation="horizontal" flexItem />

                    <Typography className={classes.pos} color="textSecondary">
                        Notes: {origin.note_collection}
                    </Typography>
                    <br />
                    <Typography className={classes.pos} color="textSecondary">
                        Comments: {origin.comment}
                    </Typography>


                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions>
        </Card>
    )
}


export default OriginInfo;