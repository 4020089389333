import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.css'

/* PAGINATION COMPONENT */

  // PROPS:
  // page: current page
  // pagesTotal: name of the facet field
  // gotoPageFunction: function which takes in new page number


const Pagination = ({ page, pagesTotal, gotoPageFunction })  => {

  // calculate the start page of pagination
  const startPage =
    pagesTotal > 9 && page >= 5
      ? pagesTotal >= page + 5
        ? page - 4
        : pagesTotal - 8
      : 1;


  // generate link array
  let pagiLinks = [];
  if (page !== 1) {
    pagiLinks.push(<button className="pagination-link pagination-link--first" key={ 'pl-first' } onClick={() => gotoPageFunction(1)}>ensimmäinen</button>)
    pagiLinks.push(<button className="pagination-link pagination-link--prev" key={ 'pl-prev' } onClick={() => gotoPageFunction(page-1)}>edellinen</button>)
  }
  for (let i=startPage; (i <= pagesTotal && i < startPage+9); i++) {
    (i === page)
      ? pagiLinks.push(<div className="pagination-link pagination-link--active" key={ 'pl-active' }>{i}</div>)
      : pagiLinks.push(<button className="pagination-link" key={ 'pl-'+i } onClick={() => gotoPageFunction(i)}>{i}</button>)
  }
  if (page < pagesTotal) {
    pagiLinks.push(<button className="pagination-link pagination-link--next" key={ 'pl-next' } onClick={() => gotoPageFunction(page+1)}>seuraava</button>)
    pagiLinks.push(<button className="pagination-link pagination-link--last" key={ 'pl-last' } onClick={() => gotoPageFunction(pagesTotal)}>viimeinen</button>)
  }

  return (
    <div className="pagination">
      { pagiLinks }
    </div>
  );
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pagesTotal: PropTypes.number.isRequired,
  gotoPageFunction: PropTypes.func.isRequired
};

export default Pagination;
