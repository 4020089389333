/* eslint-disable eqeqeq */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  HorizontalBarSeries,
  Hint,
} from 'react-vis';
import '../../../../node_modules/react-vis/dist/style.css';
import './ResultCharts.css'
import { Button } from '@material-ui/core';
import config from '../../../config.js'
import facetConstants from '../../../facetConstants.js'

const labelMap = config.labelMap;

// tools for hsl color generation
const cHue = 208;
const cSat = 56;
const cVal = 53;
const cOpa = 40;
const cHueShift = -40;
const hsl = (h, s, l) => `hsl(${h}, ${s}%, ${l}%)`;
const hsla = (h, s, l, a) => `hsla(${h}, ${s}%, ${l}%, ${a}%)`;


/* SEARCH RESULT CHARTS -COMPONENT */

function filterFacetsFromCharts(facet, origins) {
  let filterList = ['year']

  if (!origins) {
    filterList.push('origin')
  }

  if (filterList.some(el => facet.includes(el))) {
    return false
  }
  return true
}

const ResultCharts = ({
  docGroup,
  dataset,
  handleFacetChange,
  cache,
  showOrigins
}) => {

  const [animation, setAnimation] = useState(true);
  const [yearCompareTotal, setYearCompareTotal] = useState(false);
  const [yearComparePreFilter, setYearComparePreFilter] = useState(false);
  const [hintValue, setHintValue] = useState({})
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  const hasPrefilters = !!cache && Object.keys(cache).length > 0;

  const yearsTotal = useMemo(() => facetConstants[docGroup].year, [docGroup]);

  const getChartFields = () => {
    const facetFields = config.docGroups[docGroup].facetFields

    return facetFields.filter(f => filterFacetsFromCharts(f, showOrigins))
  }

  const [chartFields, setChartFields] = useState(getChartFields())

  useEffect(() => {
    setChartFields(getChartFields())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOrigins])

  // Use effect hook to keep track of mouse position for tooltips
  useEffect(() => {
    const setFromEvent = e => setMousePos({ x: e.layerX, y: e.layerY });
    window.addEventListener("mousemove", setFromEvent);
    return () => window.removeEventListener("mousemove", setFromEvent);
  }, []);

  // Reorganize bucket data for react-vis charts, 
  const datas = useMemo(() => (
    Object.entries(dataset)
      .reduce((accum, [field, values]) => (
        {
          ...accum,
          [field]: values.buckets.map(bucket => (
            {
              id: field,
              x: bucket.doc_count,
              y: bucket.key,
            }
          )).reverse()
        }
      ), {})
  ), [dataset]);

  // Invert x & y for horizontal barchart "Years" to new array
  const years = useMemo(() => (
    Object.keys(yearsTotal).map(year => {
      const vals = datas['year'].find(obj => obj.y == year)
      return {
        id: "year",
        x: year,
        y: vals ? vals.x : 0
      }
    })
  ), [datas, yearsTotal]);

  // Calculate data for items not in results by year
  const yearsRest = useMemo(() => (
    Object.keys(yearsTotal).map(year => {
      const vals = years.find(obj => (obj.x == year))
      return {
        id: "yearTotal",
        x: year,
        y: yearsTotal[year] - vals.y
      }
    })
  ), [years, yearsTotal]);

  // Calculate data for items not in results by year
  const yearsRestPreFilter = useMemo(() => (
    hasPrefilters ? (
      Object.keys(yearsTotal).map(year => {
        const vals = years.find(obj => (obj.x == year))
        const valsPre = cache.year.buckets.find(obj => (obj.key == year))
        return {
          id: "yearTotalPrefilter",
          x: year,
          y: valsPre ? valsPre.doc_count - vals.y : 0
        }
      })
    ) : null
  ), [cache, yearsTotal, years, hasPrefilters]);

  // Function for generating hint object for year compare, eg. { 2016: 102/153 (66,7%) }
  const generateYearHint = v => {
    const year = v.x;
    if (yearCompareTotal && yearsTotal[year] > 0) {
      const val = v.y0 || (v.id === 'year' ? v.y : 0);
      const valT = yearsTotal[year];
      return { [year]: `${val}/${valT} (${(val / valT * 100).toFixed(1)}%)` }
    } else {
      return { [year]: v.y }
    }
  }

  return (
    <div className="result-charts">

      {/* Horizontal barcharts  */}
      {chartFields.map((field, idx) => (
        datas[field] &&
        <div className="chart-wrapper" key={field}>
          <h3>{labelMap[field] || field}:</h3>
          <XYPlot
            margin={{ left: 200, right: 50 }}
            width={500}
            //width={564}
            height={600}
            yType="ordinal"
          >
            <VerticalGridLines />
            <HorizontalBarSeries
              data={datas[field]}
              onValueClick={(datapoint, event) => {
                handleFacetChange(datapoint.id, datapoint.y)
              }}
              onValueMouseOver={v => {
                if (!hintValue[field] || hintValue[field] !== v) {
                  setHintValue({ [field]: v })
                }
              }}
              onValueMouseOut={v => setHintValue({})}
              onSeriesMouseOut={v => setHintValue({})}
              color={hsl(cHue + (cHueShift * (idx + 1) % 360), cSat, cVal)}
            />
            <XAxis />
            <YAxis />
            {hintValue[field] &&
              <Hint
                x={mousePos.x - 50}
                y={mousePos.y - 50}
                value={{ [hintValue[field].y]: hintValue[field].x }}
              />}
          </XYPlot>
        </div>
      ))}

      {/* Vertical barchart for years  */}
      <div className="chart-wrapper">
        <h3>{labelMap['year'] || 'year'}</h3>
        <Button
          className="button--chart"
          style={!yearCompareTotal ? { color: '#6a7a89' } : {}}
          disableRipple
          onClick={() => {
            setYearCompareTotal(!yearCompareTotal)
            setYearComparePreFilter(false);
          }}
        >
          {yearCompareTotal
            ? "☒ Vertaa kaikkiin"
            : "☐ Vertaa kaikkiin"
          }
        </Button>
        {hasPrefilters &&
          <Button
            className="button--chart"
            style={!yearComparePreFilter ? { color: '#6a7a89' } : {}}
            disableRipple
            onClick={() => {
              setYearComparePreFilter(!yearComparePreFilter);
              setYearCompareTotal(false)
            }}
          >
            {yearComparePreFilter
              ? "☒ Vertaa esirajattuun"
              : "☐ Vertaa esirajattuun"
            }
          </Button>
        }
        <XYPlot
          margin={{ left: 50, right: 10 }}
          width={1136}
          height={350}
          xType="ordinal"
          stackBy="y"
        >
          <HorizontalGridLines />
          <VerticalBarSeries
            animation={animation}
            data={years}
            onValueClick={(datapoint, event) => {
              handleFacetChange('year', datapoint.x)
            }}
            onValueMouseOver={v => {
              setHintValue({ yearVer: generateYearHint(v) });
              setAnimation(false);
            }}
            onValueMouseOut={v => {
              setHintValue({});
              setAnimation(true);
            }}
            onSeriesMouseOut={v => {
              setHintValue({});
              setAnimation(true);
            }}
            color={hsl(cHue, cSat, cVal)}
          />
          {yearCompareTotal &&
            <VerticalBarSeries
              data={yearsRest}
              onValueClick={(datapoint, event) => (
                handleFacetChange('year', datapoint.x)
              )}
              onValueMouseOver={v => (
                setHintValue({ yearVer: generateYearHint(v) })
              )}
              onValueMouseOut={v => {
                setAnimation(true);
                setHintValue({});
              }}
              onSeriesMouseOut={v => {
                setAnimation(true);
                setHintValue({});
              }}
              color={hsla(cHue, cSat, cVal, cOpa)}
            />
          }
          {yearComparePreFilter &&
            <VerticalBarSeries
              data={yearsRestPreFilter}
              onValueClick={(datapoint, event) => (
                handleFacetChange('year', datapoint.x)
              )}
              onValueMouseOver={v => (
                setHintValue({ yearVer: generateYearHint(v) })
              )}
              onValueMouseOut={v => {
                setAnimation(true);
                setHintValue({});
              }}
              onSeriesMouseOut={v => {
                setAnimation(true);
                setHintValue({});
              }}
              color={hsla(cHue, cSat, cVal, cOpa)}
            />
          }
          <XAxis />
          <YAxis />
          {(hintValue['yearVer'] && mousePos.x > 50) &&
            <Hint
              x={mousePos.x - 50}
              y={mousePos.y - 20}
              value={hintValue['yearVer']}
            />}
        </XYPlot>
      </div>
    </div>
  )
}

ResultCharts.propTypes = {
  docGroup: PropTypes.string.isRequired,
  dataset: PropTypes.object.isRequired,
  handleFacetChange: PropTypes.func.isRequired,
  cache: PropTypes.object.isRequired,
};

export default ResultCharts;
