import React, { useEffect, useState, useRef } from 'react';
import './GardenMap.css';
import config from '../../config';

const { apiUrl, baseUrl, gardenCoordinates } = config

require("proj4leaflet");
var L = require("leaflet-mml-layers");

const outsideParenthesis = /(^.*\(|\).*$)/g;

// https://gisgeography.com/dem-dsm-dtm-differences/
const tileTypes = [
    {
        name: 'Orthomosaic',
        attr: '',
        url: 'mosaic',
    },
    {
        name: 'DTM',
        attr: 'Digital Terrain Model',
        url: 'dtm',
    },
    {
        name: 'DSM',
        attr: 'Digital Surface Model',
        url: 'dsm',
    }
]

const GardenMap = ({
    searchParams,
    garden
}) => {

    // map needs to be set up after the DOM has been rendered
    const [coordinates, setCoordinates] = useState([])
    const [map, setMap] = useState(null)
    const [renderer] = useState(L.canvas({ padding: 0.5 }))
    const [markerGroup] = useState(L.layerGroup())

    // set markers when coordinates change
    const mounted = useRef();

    async function fetchTree(treeId) {
        try {
            const response = await fetch(apiUrl + "tree/" + treeId);
            if (!response.ok) {
                throw Error(response.statusText);
            }

            const result = await response.json();
            alert(JSON.stringify(result, null, 4))

        } catch (error) {
            console.log(error)
            return null
        }
    }

    // fetch new coordinates when facets change
    useEffect(() => {
        const fetchCoordinates = async () => {
            try {
                const response = await fetch(apiUrl + "coords/garden" + searchParams);
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                const result = await response.json();

                // reformat [id, "POINT Z (x, y ,z)", alive_status] -> [x, y, z, od, alive_status]
                // should this be done in the background?
                setCoordinates(
                    result.map((row) => {
                        let rv = row.geom.replace(outsideParenthesis, '').split(" ")
                        rv.push(row.id)
                        rv.push(row.alive)
                        return rv
                    })
                );

            } catch (error) {
                console.log(error)
                return null
            }
        }

        fetchCoordinates()

    }, [searchParams]);


    useEffect(() => {

        // Define maps
        let taustakartta = L.tileLayer.mml('Taustakartta');
        let selkokartta = L.tileLayer.mml('Peruskartta');
        let ortokartta = L.tileLayer.mml('Ortokuva');

        // Define coordinate systems (TODO: 3067Proj don't work with overlaymap!!!)
        // var mml_crs    = L.TileLayer.MML.get3067Proj();     // MML Finnish maps
        //var normal_crs = L.CRS.EPSG4326; // OSM, Google and other world wide maps
        var normal_crs = L.CRS.EPSG3857; // OSM, Google and other world wide maps

        var baseMaps = {
            "Selkokartta": selkokartta,
            "Taustakartta": taustakartta,
            "Ilmakuva": ortokartta,
        };

        // set custom image layers (orthomosaic, dtm, dsm)
        let overLays = {}

        tileTypes.forEach(tile => {
            overLays[tile.name] = L.tileLayer(
                baseUrl + 'images/' + garden + '/' + tile.url + '/{z}/{x}/{y}.png',
                {
                    tms: true,
                    opacity: 0.7,
                    attribution: tile.attr,
                    maxZoom: tile.name === 'DTM' ? 20 : tile.name === 'DSM' ? 21 : 22
                }
            );
        })

        function markerOnClick(e) {
            let treeId = e.sourceTarget.options.treeId
            fetchTree(treeId)
            console.log(this.getLatLng())
        }


        // trigger only on first render
        if (!mounted.current) {
            mounted.current = true;

            // Start with MML map
            let initMap = L.map('map', {
                crs: normal_crs,
                //crs: mml_crs,
                preferCanvas: true
            }).setView(gardenCoordinates[garden].center, 14)

            // Default to maastokartta
            initMap.addLayer(ortokartta);
            //L.control.layers(baseMaps).addTo(initMap);
            L.control.layers(baseMaps, overLays, { collapsed: false }).addTo(initMap);

            // set orthomosaic ON automatically
            overLays['Orthomosaic'].addTo(initMap)

            // fit map around bounding box
            initMap.fitBounds(gardenCoordinates[garden].bounds);

            initMap.on('click', function (e) {
                var coord = e.latlng;
                var lat = coord.lat;
                var lng = coord.lng;
                console.log("You clicked the map at latitude: " + lat + " and longitude: " + lng);
            });

            // Set initialized map to state
            setMap(initMap)

        } else {
            markerGroup.clearLayers();
            coordinates.forEach(coords => {
                L.circleMarker(coords.slice(0, 2).reverse(), {
                    renderer: renderer,
                    color: coords[4] === 1 ? '#00FF00' : '#ff0000',
                    ZCoord: coords[2],
                    treeId: coords[3]
                })
                    .on('click', markerOnClick)
                    .addTo(markerGroup)
            })
            map.addLayer(markerGroup);

        }


    }, [coordinates]) // eslint-disable-line react-hooks/exhaustive-deps


    return (

        <div id="map"></div>

    )
}

export default GardenMap;
