
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
// import Checkbox from '@material-ui/core/Checkbox';

import './DownloadForm.css'
export class DownloadForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      format: 'csv',
      errors: false,
      showError: false,
      errorMessage: '',
      measurements: {
        chest: false,
        diam: false,
        dna_sample: false,
        height: false,
        pheno_fall: false,
        pheno_spring: false,
        pollen: false,
        seed: false,
      }
    }
  }

  submit = () => {
    if (this.state.errors) {
      this.setState({
        showError: true,
        errorMessage: "get error message"
      })
    } else {
      this.props.downloadCallback(this.state)
    }
  }

  toggleCheckbox = () => {
    this.setState({
      content: !this.state.content
    }, function () {
      console.log(this.state);
    });
  }

  toggleOption = (event) => {
    this.setState({
      format: event.target.value,
      errors: false
    }, function () {
      console.log(this.state);
    });
  }


  handleChange = name => event => {
    let measurements = { ...this.state.measurements }
    measurements[name] = event.target.checked
    this.setState({ measurements })
  }

  render() {

    return (
      <div className="filters">
        <div className="filters-content" >

          <Grid container>
            {/* <Grid item className="filters-grid-element" >
              <FormLabel component="legend">Ladataanko dokumenttien sisältö?</FormLabel>
              <Checkbox
                checked={this.state.content}
                onChange={this.toggleCheckbox}
              />
            </Grid> */}

            <Grid item className="filters-grid-element">
              <FormControl component="fieldset" className={null}>
                <FormLabel component="legend">Format</FormLabel>
                <RadioGroup
                  aria-label="format"
                  className={null}
                  onChange={null}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={this.state.format === 'csv'}
                        onChange={this.toggleOption}
                        value="csv"
                        name="filters-radio-button"
                        aria-label="CSV"
                      />
                    }
                    label="CSV"
                    labelPlacement="start"
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        checked={this.state.format === 'xlsx'}
                        onChange={this.toggleOption}
                        value="xlsx"
                        name="filters-radio-button"
                        aria-label="Excel"
                      />
                    }
                    label="Excel"
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item className="filters-grid-element">

              <FormControl component="fieldset" className="form--measurements">
                <FormLabel component="legend">Measurements</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox checked={this.state.measurements.chest} onChange={this.handleChange('chest')} value="chest" />}
                    label="Tree diameter at 1.3m"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={this.state.measurements.diam} onChange={this.handleChange('diam')} value="diam" />}
                    label="Branch diameters"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={this.state.measurements.dna_sample} onChange={this.handleChange('dna_sample')} value="dna_sample" />
                    }
                    label="DNA sample taken"
                  />
                </FormGroup>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox checked={this.state.measurements.height} onChange={this.handleChange('height')} value="height" />
                    }
                    label="Tree height"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={this.state.measurements.pheno_fall} onChange={this.handleChange('pheno_fall')} value="pheno_fall" />
                    }
                    label="Phenology fall"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={this.state.measurements.pheno_spring} onChange={this.handleChange('pheno_spring')} value="pheno_spring" />
                    }
                    label="Phenology spring"
                  />
                </FormGroup>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox checked={this.state.measurements.pollen} onChange={this.handleChange('pollen')} value="pollen" />
                    }
                    label="Pollen (male catkin no & pollen percent)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={this.state.measurements.seed} onChange={this.handleChange('seed')} value="seed" />
                    }
                    label="Seed (female catkin no & seed percent)"
                  />
                </FormGroup>
                <FormHelperText>Select measurement types you want to append to the datatable</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Button
            className="filters-load-button"
            variant="outlined"
            onClick={this.submit}
          >Lataa</Button>

          {this.state.showError &&
            <div className="filters-error-message">
              {this.state.errorMessage}
            </div>
          }

        </div>
      </div>
    )
  }
}


DownloadForm.propTypes = {
  downloadCallback: PropTypes.func.isRequired,
};

export default DownloadForm;
