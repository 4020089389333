import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import config from '../../../config.js'
import { Block, ShowMore } from '../../'
import './FacetBlock.css'
import { sortByKey } from '../../../utils/arrayTools.js';


/* FACET BLOCK COMPONENT */

// PROPS:
// facets: aggreration buckets from Elasticsearch
// field: name of the facet field
// filters: list of active filters on the field
// onChange: onChange -function to toggle facet
// sorting: initial sorting order (count/alpha), default is 'count'


function FacetBlock({
  facets,
  field,
  filters,
  onChange,
  sorting: initialSorting,
}) {

  const labelMap = config.labelMap;
  const defDirections = { 'key': 'asc', 'doc_count': 'desc' };
  const topRef = useRef();
  const [sorting, setSorting] = useState(initialSorting);
  const [sortDirection, setSortDirection] = useState(defDirections[initialSorting]);
  const [isOpen, setIsOpen] = useState(false);

  // scroll to top of the facet block
  const scrollToTop = () => {
    window.scrollTo({
      top: topRef.current.offsetTop - 20,
      behavior: "smooth"
    })
  }

  // toggle 'show more/less'
  const toggleOpen = () => {
    setIsOpen(!isOpen);
    scrollToTop();
  }

  const handleSortingChange = event => {
    const newType = event.currentTarget.getAttribute('sorttype');
    if (sorting !== newType) {
      setSorting(newType);
      setSortDirection(defDirections[newType]);
    } else {
      setSortDirection((sortDirection === 'asc') ? 'desc' : 'asc')
    }
  }

  const facetRenderList = (isOpen
      ? sortByKey([...facets], sorting, sortDirection)
      : sortByKey([...facets], sorting, sortDirection).slice(0, 5))

  return (
    <Block
      className="block--facet"
      ref={topRef}
    >
      <div className="facet-header-wrapper">
        <div className="facet-header facet-header--primary"
          sorttype='key'
          onClick={handleSortingChange}>
          <h4>{labelMap[field] || field}</h4>
        </div>
        <div className="facet-header facet-header--secondary"
          sorttype='doc_count'
          onClick={handleSortingChange}>
          kpl
        </div>
      </div>

      <div className="facet-checkboxes">
        {facetRenderList.map(item =>
          <label key={item.key} className="checkbox-label">
            <div className="facet-name">{item.key}</div>
            <div className="facet-count">{item.doc_count}</div>
            <input
              type="checkbox"
              checked={filters.includes(item.key) || filters.includes(item.key.toString(10))}
              group={field}
              onChange={onChange}
              key={item.key}
              value={item.key} />
            <span className="facet-check"></span>
          </label>
        )
        }
      </div>

      {(facets.length > 5) &&
        (
          <ShowMore
            isOpen={isOpen}
            toggleFunction={toggleOpen}
            moreLabel={"näytä kaikki (" + facets.length + ")"}
            lessLabel="näytä vähemmän"
          />
        )
      }

    </Block>
  );
}

FacetBlock.propTypes = {
  facets: PropTypes.array.isRequired,
  filters: PropTypes.array,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  //onChange: PropTypes.isRequired,
  sorting: PropTypes.string,
};

FacetBlock.defaultProps = {
  sorting: 'count',
};

export default FacetBlock;