import React, { useState, useContext } from 'react';
import config from '../../config.js'
import { store } from '../../store.js'
import './PageHeader.css';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

/* MAIN PAGE HEADER COMPONENT */

// exampleComponent.js

const MENU = [
    {
        description: 'Gardens',
        link: '/gardens'
    },
    {
        description: 'Origins',
        link: '/origins'
    },
    {
        description: 'Trees',
        link: '/search/trees'
    },
    {
        description: 'Admin',
        link: '/auth'
    },
]

const PageHeader = function () {

    const { state } = useContext(store);
    const [menuOpen, setMenuOpen] = useState(false)

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen)
    }

    const handleLinkClick = (link_addr) => {
        setMenuOpen(false)
        //window.location.replace(config.apiUrl + link_addr)
    }

    const styles = {
        hamburgerMenuContainer: {
            //position: 'absolute',
            //top: 0,
            //left: 0,
            zIndex: '99',
            opacity: 0.9,
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            //width: '100%',
            color: 'black',
            fontFamily: 'Lobster',
            margin: '0.5rem',
        },
        logo: {
            margin: '0 auto',
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            filter: menuOpen ? 'blur(2px)' : null,
            transition: 'filter 0.5s ease',
        },
        headerTopbar: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
        loggedIcon: {
            padding: '1rem',
        }
    }


    const menuItems = MENU.map((val, index) => (
        <MenuItem
            key={index}
            delay={`${index * 0.1}s`}
            linkAddr={val.link}
            onClick={() => handleLinkClick()}>
            {val.description}
        </MenuItem>
    ));


    return (
        <div id="header-container">

            <div id="header-topbar" style={styles.headerTopbar}>
                <div style={styles.hamburgerMenuContainer}>
                    <MenuButton open={menuOpen} onClick={handleMenuClick} color='black' />
                </div>

                <Menu open={menuOpen}>
                    {menuItems}
                </Menu>

                <div id="logged-icon" style={styles.loggedIcon} >
                    {Object.keys(state).length === 0 && state.constructor === Object ? (
                        <Tooltip title="Not logged in">
                            <LockIcon />
                        </Tooltip>
                    ) : (
                            <Tooltip title={state.user}>
                                <AccountCircleIcon />
                            </Tooltip>
                        )}
                </div>

            </div>

            <div id="header-title">
                <a href="/" className="link-to-front">
                    <div className="site-name">
                        {config.site.name}
                    </div>
                </a>
            </div>
        </div>
    )
}


const MenuItem = ({ onClick, delay, children, linkAddr }) => {

    const [hover, setHover] = useState(false)

    const handleHover = () => {
        setHover(!hover)
    }

    const styles = {
        container: {
            opacity: 0,
            animation: '1s appear forwards',
            animationDelay: delay,
        },
        menuItem: {
            margin: '1em 1em 1em 3em',
            fontFamily: `'Open Sans', sans-serif`,
            fontSize: '1.7rem',
            cursor: 'pointer',
            color: hover ? 'gray' : '#fafafa',
            transition: 'color 0.3s ease-in-out',
            animation: '0.03s slideIn forwards',
            animationDelay: delay,
            textDecoration: 'none',
        },
        line: {
            margin: '0.5em 3em',
            height: '2px',
            background: 'gray',
            animation: '0.5s shrink forwards',
            animationDelay: delay,
        }
    }

    return (
        <div style={styles.container}>

            <Link
                to={linkAddr}
                style={styles.menuItem}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                onClick={onClick}
            >
                {children}
            </Link>
            <div style={styles.line} />
        </div>
    )
}

const Menu = ({ open, children }) => {

    const styles = {
        container: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: open ? '100%' : 0,
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            background: 'black',
            opacity: 0.95,
            color: '#fafafa',
            transition: 'height 0.3s ease',
            zIndex: '88',
        },
        menuList: {
            paddingTop: '3rem',
        }
    }

    return (
        <div style={styles.container}>
            {open ?
                <div style={styles.menuList}>
                    {children}
                </div> : null
            }
        </div>
    )
}


const MenuButton = ({ open, color, onClick }) => {

    const styles = {
        container: {
            height: '32px',
            width: '32px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '4px',
        },
        line: {
            height: '2px',
            width: '20px',
            background: color,
            transition: 'all 0.2s ease',
        },
        lineTop: {
            transform: open ? 'rotate(45deg)' : 'none',
            transformOrigin: 'top left',
            marginBottom: '5px',
        },
        lineMiddle: {
            opacity: open ? 0 : 1,
            transform: open ? 'translateX(-16px)' : 'none',
        },
        lineBottom: {
            transform: open ? 'translateX(-1px) rotate(-45deg)' : 'none',
            transformOrigin: 'top left',
            marginTop: '5px',
        },
    }

    return (
        <div style={styles.container}
            onClick={onClick}>
            <div style={{ ...styles.line, ...styles.lineTop }} />
            <div style={{ ...styles.line, ...styles.lineMiddle }} />
            <div style={{ ...styles.line, ...styles.lineBottom }} />
        </div>
    )
}

export default PageHeader;