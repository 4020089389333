import React from 'react';
import './SearchResults.css'
import { useHistory } from "react-router-dom";


/*
import MUIDataTable from "mui-datatables";

const SearchResults = ({
  hits,
  docGroup,
  submittedTerm,
  page,
  size,
}) => {


  const dataTableOptions = {
    responsive: 'stacked',
    selectableRows: 'none',
    filterType: 'multiselect',
    //download: false,
    print: false,
    viewColumns: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100],
    textLabels: {
      body: {
        noMatch: 'Ei rajaukseen sopivia yhteydenottoja',
        toolTip: 'Järjestä',
      },
      pagination: {
        next: 'Seuraava sivu',
        previous: 'Edellinen sivu',
        rowsPerPage: 'Tuloksia sivulla:',
        displayRows: '/',
      },
      toolbar: {
        search: 'Hae',
        downloadCsv: 'Lataa CSV',
        print: 'Tulosta',
        viewColumns: 'Näytettävät sarakkeet',
        filterTable: 'Rajaa näkymää',
      },
      filter: {
        all: 'Kaikki',
        title: 'SUODATTIMET',
        reset: 'Tyhjennä',
      },
      viewColumns: {
        title: 'Näytä sarakkeet',
        titleAria: 'Näytä/piilota sarakkeet',
      },
      selectedRows: {
        text: 'rivi(ä) valittu',
        delete: 'Poista',
        deleteAria: 'Poista valitut rivit',
      },
    },
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true
      },
    },
    onRowClick: (rowData, rowMeta) => {
    }
  };



  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        filter: false,
      },
    },
  {
    name: "replica",
    label: "Replica",
    options: {
    filter: true,
    sort: false,
    }
  },
  {
    name: "garden_id",
    label: "Garden",
    options: {
    filter: true,
    sort: false,
    }
  },
  {
    name: "tree_no",
    label: "Tree number",
    options: {
    filter: true,
    sort: false,
    }
  },
  {
    name: "origin_code",
    label: "Origin",
    options: {
    filter: true,
    sort: false,
    }
  },
  {
    name: "geom",
    label: "Coordinates",
    options: {
    filter: true,
    sort: false,
    }
  },
  {
    name: "alive",
    label: "Alive",
    options: {
    filter: true,
    sort: false,
    }
  },
  ];



  return (
    <MUIDataTable
      title={"Filtered trees"}
      data={hits}
      columns={columns}
      options={dataTableOptions}
    />
  )
}

SearchResults.propTypes = {
  hits: PropTypes.array.isRequired,
  docGroup: PropTypes.string.isRequired,
  submittedTerm: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
};

export default SearchResults;
*/



import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },

}));

const SearchResults = ({
  hits
}) => {

  let history = useHistory();
  const classes = useStyles();

  const handleRowClick = (event, treeId) => {
    history.push("/tree/" + treeId);
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Garden</TableCell>
              <TableCell align="right">Replica</TableCell>
              <TableCell align="right">Row</TableCell>
              <TableCell align="right">Tree number</TableCell>
              <TableCell align="right">Origin</TableCell>
              <TableCell align="right">Genus</TableCell>
              <TableCell align="right">Species</TableCell>
              <TableCell align="right">Planting year</TableCell>
              <TableCell align="right">Alive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hits.map(row => (
              <TableRow
                key={row.Tree.id}
                hover
                onClick={event => handleRowClick(event, row.Tree.id)}
              >

                <TableCell component="th" scope="row">{row.Tree.garden_id}</TableCell>
                <TableCell align="right">{row.Tree.replica}</TableCell>
                <TableCell align="right">{row.Tree.row_no}</TableCell>
                <TableCell align="right">{row.Tree.tree_no}</TableCell>
                <TableCell align="right">{row.Tree.origin_code}</TableCell>
                <TableCell align="right">{row.Tree.genus}</TableCell>
                <TableCell align="right">{row.Tree.species}</TableCell>
                <TableCell align="right">{row.Tree.planting_year}</TableCell>
                <TableCell align="right">{row.Tree.alive}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

export default SearchResults;
