import React, { useState, useRef, useEffect } from 'react';
import './Origin.css';
import { PageHeader, Section } from "../../components";
import {
  Grid,
  Paper,
  ListItem,
  List
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import config from '../../config';
import facetConstants from '../../facetConstants'
import OriginInfo from './OriginInfo';

import { setQueryStringWithoutPageReload, getQueryStringValue } from '../../utils/queryString'

const { apiUrl } = config

require("proj4leaflet");
var L = require("leaflet-mml-layers");

const outsideParenthesis = /(^.*\(|\).*$)/g;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ListItems = ({
  parent,
  items,
  handleToggle
}) => {
  return Object.entries(items).map((item, key) => {
    const labelId = `checkbox-list-label-${parent}-${key}`;
    return (
      <ListItem
        id={labelId}
        key={key}
        role={undefined}
        dense button
        onClick={() => handleToggle(labelId, parent, item[0])}
        className="filter-list-item"
      //className={selectedGarden && Object.keys(selectedGarden)[0] === value ? classes.selected : null}
      >
        {item[0]} - {item[1]}
        {/*<ListItemText id={labelId} primary={item[0]} secondary={item[1]} />*/}
      </ListItem>
    )
  })

}


const Origin = () => {

  const useStyles = makeStyles((theme) => ({
    gardenList: {
      maxWidth: 200,
      margin: 20,
      marginTop: 0,
      backgroundColor: theme.palette.background.paper,
    },
    selected: {
      backgroundColor: '#5f9ea0',
    },
    listHeading: {
      margin: 0,
      padding: 20,
    },
    gardenInfoSection: {
      padding: 20,
    },
    gardenData: {
      height: 750,
    },
    header: {
      margin: '1rem 5rem',
    },
    filterHeader: {
      paddingLeft: 16,
      margin: 0,
    }

  }));

  const classes = useStyles();

  // map needs to be set up after the DOM has been rendered
  const [map, setMap] = useState(null)
  const [renderer] = useState(L.canvas({ padding: 0.5 }))
  // const [markerGroup] = useState(L.layerGroup())
  const [coordinates, setCoordinates] = useState([])
  const [featureGroup] = useState(L.featureGroup([]))

  const [origin, setOrigin] = useState()
  const [selectedOriginCode, setSelectedOriginCode] = useState('')
  const [searchParams, setSearchParams] = useState('?')

  // set markers when coordinates change
  const mounted = useRef();

  async function fetchOrigin(originCode) {
    const encodedOrigin = encodeURIComponent(originCode)
    try {

      const response = await fetch(apiUrl + "origin/" + encodedOrigin)
      if (!response.ok) {
        throw Error(response.statusText);
      }

      const result = await response.json();

      setOrigin(result)

    } catch (error) {
      console.log(error)
      return null
    }
  }

  const updateOrigins = (parent, key, item) => {
    let siblings = document.getElementsByClassName("filter-list-item")
    for (let i = 0; i < siblings.length; i++) {
      siblings[i].classList.remove('selected-filter')
    }
    document.getElementById(parent).classList.add("selected-filter")

    setSearchParams(`?${key}=${item}`)
  }

  useEffect(() => {
    let preSelectedOriginCode = getQueryStringValue('code')

    if (preSelectedOriginCode) {
      fetchOrigin(preSelectedOriginCode)
      setSelectedOriginCode(preSelectedOriginCode)
    }
  }, [])

  // fetch new coordinates when facets change
  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        const response = await fetch(apiUrl + "coords/origin" + searchParams);
        if (!response.ok) {
          throw Error(response.statusText);
        }

        const result = await response.json();

        setCoordinates(
          result.map((row) => {
            let rv = row.geom.replace(outsideParenthesis, '').split(" ")
            rv.push(row.code)
            return rv
          })
        );

      } catch (error) {
        console.log(error)
        return null
      }
    }

    fetchCoordinates()

  }, [searchParams]);

  useEffect(() => {
    // Define coordinate systems (TODO: 3067Proj don't work with overlaymap!!!)
    // OSM, Google and other world wide maps
    var normal_crs = L.CRS.EPSG3857;

    function markerOnClick(e) {

      Object.entries(featureGroup._layers).forEach(layer => {
        layer[1].options.color = '#ff0000'
      })
      e.sourceTarget.options.color = 'yellow'

      // refresh map
      map.fitBounds(map.getBounds());

      let originCode = e.sourceTarget.options.originCode;
      setQueryStringWithoutPageReload(`?code=${originCode}`)
      fetchOrigin(originCode)

    }

    if (!mounted.current) {
      mounted.current = true

      // Start with MML map
      let initMap = L.map('originsMap', {
        crs: normal_crs,
        //crs: mml_crs,
        preferCanvas: true
      }).setView([54.9600, 5.5900], 1)

      // Layer Map
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(initMap)

      //initMap.fitBounds([[69.7701277329, 27.0153603323], [69.7745129055, 27.0105164285]]);

      initMap.on('click', function (e) {
        var coord = e.latlng;
        var lat = coord.lat;
        var lng = coord.lng;
        console.log("You clicked the map at latitude: " + lat + " and longitude: " + lng);
      });

      // Set initialized map to state
      setMap(initMap)


    } else {

      let topMarker = null;

      featureGroup.clearLayers();
      coordinates.forEach(coords => {
        let marker = L.circleMarker(coords.slice(0, 2).reverse(), {
          renderer: renderer,
          color: selectedOriginCode === coords[3] ? 'yellow' : '#ff0000',
          ZCoord: coords[2],
          originCode: coords[3]
        }).on('click', markerOnClick)
        //.addTo(markerGroup)

        if (selectedOriginCode === coords[3]) {
          topMarker = marker;
        }

        featureGroup.addLayer(marker)
      })

      // new way
      featureGroup.addTo(map)

      if (topMarker) {
        topMarker.bringToFront()
      }

      map.fitBounds(featureGroup.getBounds())

      var myIcon = L.divIcon({ className: 'map-hint-icon', html: 'Show origin data by clicking a marker' });
      // you can set .my-div-icon styles in CSS
      L.marker([0, -180], { icon: myIcon }).addTo(map);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates])


  return (
    <>
      <Section className="section--header">
        <PageHeader />
      </Section>

      <h1 className={classes.header}>
        Origins
      </h1>

      <Section className="section--content">
        <Grid
          container
          justify="center"
          spacing={2}
        >
          <Grid item xs={12} sm={10} md={9} >
            <Paper elevation={2}>
              <div id="originsMap"></div>

              {origin &&
                <OriginInfo origin={origin} />
              }
            </Paper>
          </Grid>

          <Grid item xs={12} sm={2} md={3} >
            <Paper elevation={2} >
              {Object.entries(facetConstants.origins).map(value => {
                return (
                  <div>
                    <h2 className={classes.filterHeader}>
                      {capitalizeFirstLetter(value[0])}
                    </h2>
                    <List key={value[0]}>
                      <ListItems parent={value[0]} items={value[1]} handleToggle={updateOrigins} />
                    </List>
                    <hr />
                  </div>
                )
              })}
            </Paper>
          </Grid>

        </Grid>

      </Section>
    </>
  )
}

export default Origin;
