import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import {
  Grid,
  Paper,
  Chip,
  Button,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import { Pagination, DownloadForm, GardenMap } from '..';
import {
  FacetBlock,
  SearchResults,
  ResultCharts,
} from '.';
import config from '../../config';
import './SearchForm.css';

/* TODO: make move all api-calls to actions */
const { apiUrl } = config;
const sortDefault = 'score';
const sizeDefault = 50;
//const sizeOptions = [5, 10, 25, 50, 100, 250, 500];
//const sortOptions = ['sort', 'options']

/* function to reduce array of keys into object with empty arrays with those keys */
const filterInitializer = (accum, item) => { accum[item] = []; return accum; };

/* The s2ab (string to array buffer) method 
Ref: https://github.com/SheetJS/js-xlsx/blob/master/README.md) */
function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

/* -------------------- *\
  SEARCH FORM -COMPONENT
\* -------------------- */

class SearchForm extends Component {

  constructor(props) {
    super(props);
    const values = queryString.parse(this.props.location.search);
    this._isMounted = false;
    this.searchParams = '';
    this.facetFields = config.docGroups[this.props.docGroup].facetFields;
    this.state = {
      activeFormType: 'basic',
      sort: values.sort || sortDefault,
      size: parseInt(values.size) || sizeDefault,
      page: parseInt(values.page) || 1,
      pagesTotal: 0,
      filters: { ...this.facetFields.reduce(filterInitializer, {}) },
      totalHits: 0,
      error: null,
      isLoaded: false,
      result: {},
      facetCacheForBlocks: {},
      facetCacheForPrefilters: {},
      showDownloadForm: false,
      loadingDataFromBackend: false,
      showOrigins: false,
      selectedView: 'graph'
    };
    // Create ref-objects
    this.searchInfoRef = React.createRef();
  }

  async componentDidMount() {
    this._isMounted = true;
    // if search parameters in url, handle them and fetch needed searches

    await this.fetchTotalAmountOfTrees();

    if (this.props.location.search) {
      const values = queryString.parse(this.props.location.search);
      await this.fetchSearch();
      this.setState({
        filters: this.facetFields.reduce((accum, field) => {
          accum[field] =
            !values[field]
              ? []
              : Array.isArray(values[field])
                ? values[field]
                : [values[field]]
          return accum;
        }, [])
      });
      if (values.proximity) { }
      if ((values.page || this.hasFilters()) && !this.state.error) {
        await this.fetchSearch(true);
      }
    } else {
      await this.fetchSearch();
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.docGroup !== prevProps.docGroup) {
      const { docGroup } = this.props;
      this.facetFields = config.docGroups[docGroup].facetFields;

      await this.setState({
        error: null,
        isLoaded: false,
        page: 1,
        pagesTotal: 0,
        facetCacheForBlocks: {},
        facetCacheForPrefilters: {},
        result: {},
      });

      this.clearFilters()

      this.props.history.push({
        pathname: '/search/' + docGroup
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setShowOrigins = (filters) => {
    try {
      if (!(filters.genus.length === 0 && filters.species.length === 0 && filters.origin.length === 0)) {
        this.setState({ showOrigins: true })
      } else {
        this.setState({ showOrigins: false })
      }
    } catch (err) {
      return
    }
  }

  pushHistory = () => {
    this.props.history.push({
      pathname: '/search/' + this.props.docGroup,
      search: this.searchParams
    })
  }

  scrollToResultsTop = () => {
    window.scrollTo({
      top: this.searchInfoRef.current.offsetTop - 20,
      behavior: "smooth"
    })
  }

  gotoPage = async (newPage) => {
    await this.setState({
      page: newPage,
    });
    await this.fetchSearch(true);
    this.pushHistory();
    this.scrollToResultsTop();
  }

  // return search parameter string for API call and browser history push
  buildSearchParams = () => {
    const { filters, page, size, sort } = this.state;

    const params = {
      ...filters,
      page: (page > 1) && page,
      size: (size !== sizeDefault) && size,
      sort: (sort !== sortDefault) && sort,
    }

    return "?" + Object.keys(params)
      .reduce((accum, key) => (
        (!params[key] || params[key].length === 0)
          ? accum
          : Array.isArray(params[key])
            ? [...accum, ...params[key].map(item => key + "=" + item)]
            : [...accum, key + "=" + params[key]]
      ), [])
      .join('&');
  }

  async fetchTotalAmountOfTrees() {
    const response = await fetch(apiUrl + "trees/count", { credentials: 'include' });
    const result = await response.json()
    this.setState({ totalHits: result })
  }

  // make search call to API (if update flag on, no need to rewrite facets)
  async fetchSearch(update = false) {
    const { size, filters } = this.state;
    const { docGroup } = this.props;

    if (!update) {
      await this.setState({
        isLoaded: false,
        error: false,
      });
    }

    this.searchParams = this.buildSearchParams();

    this.setShowOrigins(filters)

    try {
      const response = await fetch(apiUrl + "search/" + docGroup + this.searchParams,);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const result = await response.json();

      console.log(result)

      if (this._isMounted) {
        this.setState({
          pagesTotal: Math.ceil(result.hits.total / size),
          isLoaded: true,
          result: result,
          facetCacheForBlocks: result.aggregations,
        });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({
          error
        });
      }
    }
  }

  handleResultSizeChange = async (event) => {
    const { size } = this.state;
    if (size !== event.target.value) {
      await this.setState({
        page: 1,
        size: event.target.value,
      });
      await this.fetchSearch();
      this.pushHistory();
      this.scrollToResultsTop();
    }
  }

  handleResultSortChange = async (event) => {
    const { sort } = this.state;
    if (sort !== event.target.value) {
      await this.setState({
        page: 1,
        sort: event.target.value,
      });
      await this.fetchSearch();
      this.pushHistory();
      this.scrollToResultsTop();
    }
  }

  // functions for search filters
  clearFilters = () => {
    this.setState({
      filters: { ...this.facetFields.reduce(filterInitializer, {}) },
    });
  }

  hasFilters = () => {
    const { filters } = this.state;
    return this.facetFields.some(field =>
      filters[field].length > 0
    );
  }

  // functions for facets
  handleFacetChange = async (group, value) => {
    const { filters } = this.state;
    const strValue = "" + value
    const newFilters = {
      ...filters,
      [group]: filters[group].includes(strValue)
        ? [...filters[group].filter(item => item !== strValue)]
        : [...filters[group], strValue],
    }
    await this.setState({
      page: 1,
      filters: newFilters,
    });
    await this.fetchSearch(true);
    this.pushHistory();
  }

  removeAllFacets = async () => {
    await this.setState({
      page: 1,
    })
    this.clearFilters();
    await this.fetchSearch();
    this.pushHistory();
  }


  toggleVisualize = () => {
    this.setState({
      visualize: !this.state.visualize
    });
  }

  toggleDataTable = () => {
    this.setState({
      showDataTable: !this.state.showDataTable
    })
  }

  toggleViewSelect = (viewName) => {
    this.setState({
      selectedView: viewName,
    })
  }

  downloadSearchResults = (opt) => {
    // show spinner
    this.setState({
      loadingDataFromBackend: true
    })

    // User selected data format
    var format = opt.format

    // Include checked measurements to querystring
    let queryString = '&format=' + format;
    let measurementOptions = opt.measurements;
    for (const key in measurementOptions) {
      if (measurementOptions[key]) {
        queryString += `&${key}=1`
      }
    }

    // Init variables
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    var blob, url = []

    fetch(apiUrl + "download/" + this.props.docGroup + this.searchParams + queryString)
      .then(res => res.text())
      .then(
        (result) => {
          // Create a file-like Blob object and stream it to user
          if (format === 'csv') {
            blob = new Blob([result], { type: "octet/stream" })
          } else if (format === 'xlsx') {

            // Excel data file needs to be converted to an ArrayBuffer 
            var byteArray = s2ab(atob(result));

            blob = new Blob(
              [byteArray],
              { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
            )
          }

          url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = "export." + format;
          a.click();
          window.URL.revokeObjectURL(url);

          this.setState({
            loadingDataFromBackend: false
          })

        },
        (error) => {
          console.log(error)
        }
      )
  }

  // search result list, pagination and sorting etc. tools for rendering
  renderSearchResultsArea() {
    const { facetCacheForPrefilters, error, isLoaded, result, page, pagesTotal, size, filters, showOrigins, selectedView } = this.state;
    const { docGroup } = this.props;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded || pagesTotal <= 0) {
      return null;
    } else {

      const hits = result.hits.hits;

      let pagination = null;
      if (pagesTotal > 1) {
        pagination =
          <Paper elevation={2}>
            <Pagination
              page={page}
              pagesTotal={pagesTotal}
              gotoPageFunction={this.gotoPage}
            />
          </Paper>
      }
      return (
        <div id="results" className="results-area">

          {/*
          <div className="result-chooser-area">
            <div className="result-chooser-wrapper result-chooser-wrapper--right">
              Tuloksia sivulla:
              <ResultChooser
                value={size}
                options={sizeOptions}
                handleChange={this.handleResultSizeChange}
              />
            </div>
            <div className="result-chooser-wrapper result-chooser-wrapper--right">
              Järjestys:
              <ResultChooser
                value={sort}
                options={sortOptions}
                handleChange={this.handleResultSortChange}
              />
            </div>
          </div>
          */}

          {selectedView === "table" ? pagination : null}

          <Paper elevation={2}>

            {selectedView === "map" ?
              <GardenMap
                garden={filters.garden[0]}
                searchParams={this.buildSearchParams()}
              />

              : selectedView === "graph" ?
                <Grid container spacing={4}>
                  <ResultCharts
                    docGroup={docGroup}
                    dataset={result.aggregations || {}}
                    cache={facetCacheForPrefilters}
                    handleFacetChange={this.handleFacetChange}
                    showOrigins={showOrigins}
                  />
                </Grid>
                : selectedView === "table" ?
                  <SearchResults
                    hits={hits}
                    docGroup={docGroup}
                    page={page}
                    size={size}
                  />
                  : selectedView === "export" ?
                    <div className="download-form-wrapper download-form-wrapper--open">
                      <DownloadForm
                        downloadCallback={this.downloadSearchResults}
                      />
                    </div>
                    : "no view selected"
            }
          </Paper>

          {selectedView === "table" ? pagination : null}
        </div>
      );
    }
  }

  // info text area from current search and preFilters for rendering
  renderSearchInfo = () => {
    const { error, isLoaded, totalHits, result, filters, selectedView } = this.state;

    console.log(selectedView)

    if (!result.hits || !isLoaded || error) {
      return null;
    } else {
      return <>
        <h1 className="search-header">Trees</h1>
        <div className="search-info">
          {/* hit stats */}
          osumia: {result.hits.total}/{totalHits} ({(result.hits.total / totalHits * 100).toFixed(1)}%)
        </div>

        {/* result tools - dont't show map if garden isin't selected */}
        <div className="button-area">
          <Button
            onClick={() => this.toggleViewSelect('graph')}
            className={selectedView === "graph" ? "button--selected button--right" : "button--right"}
          >Graph</Button>
          <Button
            onClick={() => this.toggleViewSelect('table')}
            className={selectedView === "table" ? "button--selected button--right" : "button--right"}
          >Trees</Button>
          {(filters.garden[0] === '1-Rassiniva' || filters.garden[0] === '2-Skallovaara' || filters.garden[0] === '3-Syysjoki') ?
            <Button
              onClick={() => this.toggleViewSelect('map')}
              className={selectedView === "map" ? "button--selected button--right" : "button--right"}
            >Map</Button>
            :
            <Tooltip title="Select garden first">
              <span>
                <Button className="button--right" disabled>Map</Button>
              </span>
            </Tooltip>
          }
          <Button
            onClick={() => this.toggleViewSelect('export')}
            className={selectedView === "export" ? "button--selected button--right" : "button--right"}
          >Export</Button>
        </div>
      </>
    }
  }

  // active facet chips (if any) for rendering
  renderActiveFacets() {
    const { filters, isLoaded } = this.state;
    if (!isLoaded || !this.hasFilters()) {
      return null;
    } else {
      return (
        <div className="facet-active-wrapper">
          {this.facetFields.map(field =>
            filters[field] &&
            (
              filters[field].map((item, idx) =>
                <Chip
                  key={idx}
                  label={item}
                  onDelete={() => this.handleFacetChange(field, item)}
                  className="facet-chip"
                />
              )
            )
          )
          }
          <Chip
            label='Poista kaikki'
            onClick={() => this.removeAllFacets()}
            className="facet-chip remove-all"
          />
        </div>
      )
    }
  }

  // facet blocks for rendering
  renderFacetBlocks() {
    const { facetCacheForBlocks, filters, result, isLoaded, error, pagesTotal } = this.state;

    const renderOrigins = (field) => {
      if (field !== 'origin') {
        return true
      } else if (!this.state.showOrigins) {
        return false
      } else {
        return true
      }
    }

    if (!isLoaded || error || (pagesTotal <= 0 && !this.hasFilters())) {
      return null;
    } else {
      return (
        <div className="facets">
          {this.facetFields.map(field => (

            result.aggregations[field] && renderOrigins(field) &&
            (
              <Paper elevation={2} key={field}>
                <FacetBlock
                  field={field}
                  onChange={
                    event => this.handleFacetChange(event.target.getAttribute('group'), event.target.value)
                  }
                  facets={facetCacheForBlocks[field].buckets}
                  filters={filters[field]}
                />
              </Paper>
            )
          )
          )}
        </div>
      );
    }
  }

  // Render it all
  render() {
    const { loadingDataFromBackend } = this.state;

    return (
      <div className="search">
        <div className="search-info-area" ref={this.searchInfoRef}>
          {this.renderSearchInfo()}
          {this.renderActiveFacets()}
        </div>

        {loadingDataFromBackend && <CircularProgress size={68} />}
        {loadingDataFromBackend && <p>loading data...</p>}

        <Grid container spacing={4}>
          <Grid item md={12} lg={9} >
            {this.renderSearchResultsArea()}
          </Grid>
          <Grid item md={12} lg={3}>
            {this.renderFacetBlocks()}
          </Grid>
        </Grid>

      </div>
    );
  }

}

export default withRouter(SearchForm);